import React from "react"

import CurrentStep from "@src/context/currentRouteContext"
import { Localization } from "@src/localization"
import InfoBar from "."

const InfoBarWithContext = (): React.ReactElement | null => {
  const createContent = React.useContext(Localization).createCampaign
  const currentStep = React.useContext(CurrentStep)

  if (!currentStep) return null

  return (
    <InfoBar
      message={createContent[currentStep].info}
      btnText={createContent[currentStep].infoBtnText}
      btnLink={createContent[currentStep].infoBtnLink}
    />
  )
}

export default InfoBarWithContext
