import React from "react"
import tw from "twin.macro"

import Layout from "@src/components/layout"
import CampaignNavigation from "@src/components/campaign/navigation"
import { Localization } from "@src/localization"
import CustomContainer from "@src/components/customContainer"

const Help = (): React.ReactElement => {
  const helpContent = React.useContext(Localization).help

  return (
    <Layout>
      <CustomContainer customCss={tw`bg-white`}>
        <div tw="flex flex-col w-full">
          {/* campaign navigation */}
          <CampaignNavigation />

          <div tw="mb-8 py-4 text-xl text-black">
            {helpContent.description}
            {helpContent.emailLabel}
            &nbsp;
            <a href={`mailto:${helpContent.email}`}>{helpContent.email}</a>
            &nbsp;
            {helpContent.phoneLabel}
            &nbsp;
            <a href={`tel:${helpContent.phone}`}>{helpContent.phone}</a>
            &nbsp;
          </div>
        </div>
      </CustomContainer>
    </Layout>
  )
}

export default Help
