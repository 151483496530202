import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby"

import CustomButton, { ButtonColors } from "@src/components/ui/button"

interface InfoBarProps {
  message?: string
  btnText?: string
  btnLink?: string
}

const InfoBar = ({
  message,
  btnText,
  btnLink,
}: InfoBarProps): React.ReactElement | null => {
  if (!message) return null
  return (
    <div css={tw`flex bg-orange-1 rounded-lg px-4 py-3 items-center`}>
      <span
        tw="max-w-4xl text-white text-sm flex-shrink leading-5"
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          __html: message,
        }}
      ></span>
      {btnText && btnLink && (
        <Link to={btnLink} tw="ml-4 flex-shrink-0">
          <CustomButton
            color={ButtonColors.white}
            customCss={[
              tw`
              font-bold
            `,
            ]}
          >
            {btnText}
          </CustomButton>
        </Link>
      )}
    </div>
  )
}

export default InfoBar
