import React from "react"
import tw from "twin.macro"

const CustomContainer = ({
  children,
  customCss,
}: {
  children: any
  customCss?: any
}): React.ReactElement => (
  <div css={[customCss, tw`px-2 md:px-8 lg:px-16 xl:px-32`]}>{children}</div>
)

export default CustomContainer
