import React from "react"
import tw, { css } from "twin.macro"

import { Localization } from "@src/localization"
import CurrentStep from "@src/context/currentRouteContext"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"

export enum StepState {
  WAIT = "WAIT",
  PROGRESS = "PROGRESS",
  FINISH = "FINISH",
  ERROR = "ERROR",
}

export enum NavigationWidth {
  TwoTen = "TwoTen",
  ThreeNine = "ThreeNine",
  FourEight = "FourEight",
  FiveSeven = "FiveSeven",
}

const navigationWidth: {
  [key in NavigationWidth]: {
    leftStyle: any
    rightStyle: any
  }
} = {
  [NavigationWidth.TwoTen]: {
    leftStyle: tw`w-2/12`,
    rightStyle: tw`w-10/12`,
  },
  [NavigationWidth.ThreeNine]: {
    leftStyle: tw`w-3/12`,
    rightStyle: tw`w-9/12`,
  },
  [NavigationWidth.FourEight]: {
    leftStyle: tw`w-4/12`,
    rightStyle: tw`w-8/12`,
  },
  [NavigationWidth.FiveSeven]: {
    leftStyle: tw`w-5/12`,
    rightStyle: tw`w-7/12`,
  },
}

const CampaignNavigation = ({
  width = NavigationWidth.FourEight,
}: {
  width?: NavigationWidth
}): React.ReactElement | null => {
  const content = React.useContext(Localization).createCampaign
  const currentStep = React.useContext(CurrentStep)

  // if current step is not defined
  if (!currentStep) return null

  return (
    <div tw="flex flex-row w-full pt-8 pb-4">
      <div
        css={css`
          ${navigationWidth[width].leftStyle}
        `}
      >
        {content[currentStep].heading && (
          <h3
            css={tw`text-gray-600 font-bold`}
            dangerouslySetInnerHTML={{
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              __html: content[currentStep].heading,
            }}
          ></h3>
        )}
        {content[currentStep].createContentTitle && (
          <h2 tw="text-4xl pt-4">{content[currentStep].createContentTitle}</h2>
        )}
      </div>
      <div
        css={css`
          ${navigationWidth[width].rightStyle}
        `}
        tw="flex flex-col justify-end"
      >
        <div tw="flex">
          {/* campaign orange info bar */}
          <InfoBarWithContext />
        </div>
      </div>
    </div>
  )
}

export default CampaignNavigation
